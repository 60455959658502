<template>
  <div>
    <div class="tab-box">
      <div></div>
      <div class="top-right">今日成交金额 ￥{{ orderPay }}</div>
    </div>
    <div class="avue-echart-table" style="margin-top: 30px">
      <el-table ref="table" :data="list" :height="650" v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.1)" :header-row-style="headerRowStyle" :header-cell-style="headerCellStyle" :cell-style="cellStyle" :row-style="rowStyle">
        <el-table-column show-overflow-tooltip prop="title" label="商品" width="180px">
          <template slot-scope="{row}">
            <div class="cell-product-title">
              <img :src="`${IMGAPI}${row.pic}`" class="cell-product-img">
              <span style="color: #76B5FD">{{ row.prod_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="info" label="动态信息">
          <template slot-scope="{row}">
            <div style="color: #B1C7DE; font-weight: bold;">
              <span style="color: #01ECFF">售出</span>
              {{ row.prod_count }}件,
              成交额
              <span style="color: #01ECFF; font-weight: bold;">{{ row.product_total_amount }}元</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="time" label="时间" width="150px">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import request from '../../axios'
import {IMGAPI} from'@/utils/define'

export default {
  name: 'dynamicItem1',
  data() {
    return {
      IMGAPI,
      list: [],
      headerRowStyle: {
        background: '#2B3F84'
      },
      headerCellStyle() {
        return {
          fontSize: 14,
          backgroundColor: '#2B3F84',
          color: '#FFFFFF',
          // textAlign: column.type == 'index' ? 'center' : this.option.headerTextAlign
        }
      },
      rowStyle() {
        return {
          backgroundColor: 'transparent'
        }
      },
      cellStyle() {
        return {
          borderWidth: '0px 0px 1px 0px',
          borderStyle: 'dashed',
          borderColor: '#2D3C8A',
          backgroundColor: 'transparent',
          color: '#B1C7DE',
          padding: '24px 0'
        }
      },
      scroll: true,
      scrollTime: 3000,
      scrollSpeed: 5,
      count: 8,
      loading: false,
      orderPay: '',
      host: 'https://goat-api.sjxfbf.com'
    }
  },
  created() {
    this.$nextTick(() => {
      this.height = 650
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      setTimeout(() => {
        this.setTime();
      }, 3000)
    })
  },
  computed: {
    cellHeight() {
      return parseInt((650 - this.headerHeight) / this.count)
    }
  },
  mounted() {
    this.getData()
    this.getOrderPay()
    setInterval(() => {
      this.getData()
      this.getOrderPay()
    }, 1000 * 60 * 5)
  },
  methods: {
    setTime() {
      clearInterval(this.scrollCheck);
      this.height = 650
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      const table = this.$refs.table
      const divData = table.bodyWrapper
      const speed = this.scrollSpeed
      let top = 0
      if (this.scroll) {
        this.scrollCheck = setInterval(() => {
          top = top + speed
          divData.scrollTop += speed;
          if (divData.clientHeight + divData.scrollTop >= divData.scrollHeight) {
            divData.scrollTop = 0
          }
          if (top >= this.cellHeight && this.scrollTime) {
            divData.scrollTop = divData.scrollTop - (top - this.cellHeight)
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              this.setTime()
            }, this.scrollTime)
          }
        }, 20)
      } else {
        divData.scrollTop = 0
      }
    },
    getOrderPay() {
      request(`${this.host}/api/system/DataInterface/451352870130839173/Actions/Response`, {
        params: {
          startTime: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          endTime: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        },
        headers: {
          Authorization: 'nRxlROHkk8YzWQwotBFnHj4getcWtYTQ::871154b1f92640f199a30017fa73b822',
          ymDate: new Date().getTime()
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.orderPay = res.data.data[0].actual_total
        }
        
      })
    },
    getData(dynamic_type) {
      this.dynamic_type = dynamic_type
      const params = {
        limitOffset: 0,
        limitRowCount: 15,
      }
      if (dynamic_type) {
        params.dynamic_type = dynamic_type
      }
      this.loading = true
      request(`${this.host}/api/system/DataInterface/451316307485290117/Actions/Response`, {
        params,
        headers: {
          Authorization: 'nRxlROHkk8YzWQwotBFnHj4getcWtYTQ::871154b1f92640f199a30017fa73b822',
          ymDate: new Date().getTime()
        }
      }).then(res => {
        this.$emit('load')
        this.loading = false
        if (res.data.code === 200) {
          this.list = res.data.data.map(item => ({
            _id: new Date().getTime(),
            ...item,
            time: dayjs(item.create_time).format('YYYY.MM.DD HH:MM'),
          }))
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab-box {
  display: flex;
  justify-content: space-between;
}

.top-right {
  color: #01ECFF;
  font-size: 14px;
}
.cell-product-img {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  margin-right: 5px;
}
.cell-product-title {
  display: flex;
  align-items: center;
}
</style>