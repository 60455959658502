<template>
  <div :class="[b(),className]" :style="styleSizeName">
    <div :ref="id" :style="styleChartName"></div>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "common",
  methods: {
    updateChart() {
      if (Array.isArray(this.dataChart) && !this.dataChart.length) return
      const optionData = this.deepClone(this.dataChart) || [];
      console.log('optionData', optionData)
      const option = this.echartFormatter && this.echartFormatter(optionData);
      this.myChart.resize();
      this.myChart.setOption(option, true);
    }
  }
});
</script>



