<template>
    <div :class="[b(),className]"
       :style="styleSizeName">
    <div :ref="id"
         :style="styleChartName"></div>
  </div>
</template>

<script>
import create from "../../create";

export default create({
  name: "map",
  data() {
    return {
      bannerCount: 0,
      bannerCheck: "",
      centerData: [],
      zoomData: 1
    };
  },
  watch: {
    mapData() {
      this.updateChart();
    },
    dataChartLen() {
      this.setBanner();
    },
    bannerTime() {
      this.setBanner();
    },
    banner: {
      handler() {
        this.setBanner();
      },
      immediate: true
    },
    width() {
      this.updateData();
    },
    height() {
      this.updateData();
    },
    zoom: {
      handler() {
        this.zoomData = this.zoom;
      },
      immediate: true
    },
  },
  computed: {
    zoomShow() {
      return this.option.zoomShow || 1;
    },
    zoom() {
      return this.option.zoom || 1;
    },
    mapData() {
      return this.option.mapData || {};
    },
    borderWidth() {
      return this.option.borderWidth || 3;
    },
    borderColor() {
      return this.option.borderColor || "#389BB7";
    },
    areaColor() {
      return this.option.areaColor || "#0c162f";
    },
    empColor() {
      return this.option.empColor || "#fff";
    },
    empAreaColor() {
      return this.option.empAreaColor || "yellow";
    },
    color() {
      return this.option.color || "#fff";
    },
    roam() {
      return this.vaildData(this.option.roam, true)
    },
    fontSize() {
      return this.option.fontSize || 24;
    },
    bannerTime() {
      return this.option.bannerTime || 3000;
    },
    banner() {
      return this.option.banner;
    },
    locationData() {
      return (this.dataChart || []).map(ele => {
        ele.zoom = ele.zoom || 1;
        const zoomData = this.zoomData < 1 ? 1 : this.zoomData;
        return Object.assign(
            (() => {
              if (ele.zoom <= zoomData) {
                return {
                  name: ele.name
                };
              }
              return {};
            })(),
            {
              value: [ele.lng, ele.lat, ele.value]
            }
        );
      });
    }
  },
  destroyed() {
    if (this.bannerCheck) {
      clearInterval(this.bannerCheck);
    }
  },
  methods: {
    resetBanner() {
      this.$nextTick(() => {
        this.myChart.dispatchAction({
          type: "hideTip"
        });
        // this.myChart.dispatchAction({
        //   type: "downplay"
        // });
      });
    },
    setBanner() {
      clearInterval(this.bannerCheck);
      if (this.banner) {
        this.bannerCheck = setInterval(() => {
          const curr = this.bannerCount % this.dataChartLen;
          this.myChart.dispatchAction({
            type: "showTip",
            seriesIndex: "0",
            dataIndex: curr
          });
          this.myChart.dispatchAction({
            type: "downplay"
          });
          this.myChart.dispatchAction({
            type: "highlight",
            dataIndex: curr
          });
          this.bannerCount += 1;
        }, this.bannerTime);
      }
      // 实现有问题，无法获取地图的实际坐标系
      // if (window.echarts && window.echarts.version && window.echarts.version >= '5.2.2') {
      //   const self = this;
      //   let dataChartLength = self.dataChart && self.dataChart.length > 0 && self.dataChart[0].features.length || 0;
      //   let features = self.dataChart && self.dataChart.length > 0 && self.dataChart[0].features;
      //   clearInterval(this.bannerCheck);
      //   if (this.banner && features && dataChartLength > 0) {
      //     this.bannerCheck = setInterval(() => {
      //       const curr = self.bannerCount % dataChartLength;
      //       const pre = curr - 1 < 0 ? dataChartLength - 1 : curr - 1;
      //       // echarts版本5.1.0开始支持geoIndex
      //       // https://echarts.apache.org/zh/api.html#action.tooltip.showTip
      //       self.myChart.dispatchAction({
      //         type: "hideTip",
      //         // dataIndex: curr,
      //         geoIndex: 0,
      //         name: features[pre] && features[pre].properties && features[pre].properties.name
      //       });
      //       self.myChart.dispatchAction({
      //         type: "showTip",
      //         // dataIndex: curr,
      //         geoIndex: 0,
      //         name: features[curr] && features[curr].properties && features[curr].properties.name
      //       });
      //       self.myChart.dispatchAction({
      //         type: "downplay",
      //         geoIndex: 0,
      //         name: features[pre] && features[pre].properties && features[pre].properties.name
      //       });
      //       self.myChart.dispatchAction({
      //         type: "highlight",
      //         // dataIndex: curr,
      //         geoIndex: 0,
      //         name: features[curr] && features[curr].properties && features[curr].properties.name
      //       });
      //       self.bannerCount += 1;
      //     }, this.bannerTime);
      //   }
      // }
    },
    // 修复数据来源多个的冲突
    updateChart(mapData, forceUpdate) {
      this.$axios(this.mapData).then(res => {
        const data = res.data;
        const optionData = this.deepClone(data);
        window.echarts.registerMap("HK", optionData);
        const option = {
          tooltip: (() => {
            return Object.assign(
              (() => {
                if (this.formatter) {
                  return {
                    formatter: name => {
                      return this.formatter(name, this.dataChart);
                    }
                  };
                }
                return {};
              })(),
              {
                backgroundColor: this.option.tipBackgroundColor,
                textStyle: {
                  fontSize: this.option.tipFontSize,
                  color: this.option.tipColor
                }
              }
            );
          })(),
          geo: Object.assign(
            (() => {
              if (!this.validatenull(this.centerData)) {
                return {
                  center: this.centerData
                };
              }
              return {};
            })(),
            {
              map: "HK",
              label: {
                emphasis: {
                  show: false
                }
              },
              zoom: this.zoomData,
              layoutCenter: ["50%", "50%"],
              layoutSize: 1200,
              roam: this.roam,
              label: {
                show: true,
                fontSize: this.fontSize,
                color: this.color
              },
              left: this.option.gridX,
              top: this.option.gridY,
              right: this.option.gridX2,
              bottom: this.option.gridY2,
              emphasis: {
                label: {
                  color: this.empColor
                },
                itemStyle: {
                  areaColor: this.empAreaColor
                }
              },
              itemStyle: {
                borderWidth: this.borderWidth,
                borderColor: this.borderColor,
                areaColor: this.areaColor
              }
            }
          ),
          series: [
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              showEffectOn: "emphasis",
              rippleEffect: {
                brushType: "fill",
                scale: 4
              },
              symbolSize: this.fontSize,
              hoverAnimation: true,
              data: this.locationData,
              label: {
                show: true,
                position: ["130%", "0"],
                fontSize: this.fontSize,
                color: this.color,
                formatter: params => {
                  return params.name;
                }
              },
              itemStyle: {
                color: this.color
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.fontSize + 20,
                  color: this.option.empColor
                },
                itemStyle: {
                  color: this.option.empColor
                }
              }
            }
          ]
        };
        this.myChart.off("mouseover");
        this.myChart.off("mouseout");
        this.myChart.off("georoam");

        this.myChart.on("mouseover", () => {
          clearInterval(this.bannerCheck);
          this.resetBanner();
        });
        this.myChart.on("mouseout", () => {
          this.bannerCount = 0;
          this.setBanner();
        });
        this.myChart.on("georoam", e => {
          const option = this.myChart.getOption();
          const geo = option.geo[0];
          this.centerData = geo.center;
          this.zoomData = geo.zoom;
          if (this.zoomData < 1) this.zoomData = 1;
        });

        this.myChart.resize();
        this.myChart.setOption(option, true);
      })

    }
    //   const self = this;
    //   window.echarts.registerMap("HK", mapData);
    //   const option = {
    //     tooltip: {
    //       show: true,
    //       trigger: 'item',
    //       position: "inside",
    //       backgroundColor: self.option.tipBackgroundColor,
    //       textStyle: {
    //         fontSize: self.option.tipFontSize,
    //         color: self.option.tipColor
    //       }
    //     },
    //     geo: Object.assign(
    //         (() => {
    //           if (!this.validatenull(this.centerData)) {
    //             return {
    //               center: this.centerData
    //             };
    //           }
    //           return {};
    //         })(),
    //         {
    //           map: "HK",
    //           zoom: this.zoomData,
    //           layoutCenter: ["50%", "50%"],
    //           layoutSize: 1200,
    //           roam: this.roam,
    //           label: {
    //             emphasis: {
    //               show: false
    //             },
    //             show: true,
    //             fontSize: this.fontSize,
    //             color: this.color
    //           },
    //           left: this.option.gridX,
    //           top: this.option.gridY,
    //           right: this.option.gridX2,
    //           bottom: this.option.gridY2,
    //           emphasis: {
    //             label: {
    //               color: this.empColor
    //             },
    //             itemStyle: {
    //               areaColor: this.empAreaColor
    //             }
    //           },
    //           itemStyle: {
    //             borderWidth: this.borderWidth,
    //             borderColor: this.borderColor,
    //             areaColor: this.areaColor
    //           },
    //           tooltip: {
    //             show: true,
    //             trigger: 'item',
    //             position: "inside",
    //             formatter: function (params) {
    //               return params.name;
    //             },
    //             backgroundColor: self.option.tipBackgroundColor || "rgba(0,0,0,1)",
    //             textStyle: {
    //               fontSize: self.option.tipFontSize,
    //               color: self.option.tipColor || "red"
    //             }
    //           }
    //         }
    //     ),
    //     series: [
    //       {
    //         type: "effectScatter",
    //         coordinateSystem: "geo",
    //         showEffectOn: "emphasis",
    //         rippleEffect: {
    //           brushType: "fill",
    //           scale: 4
    //         },
    //         symbolSize: this.fontSize,
    //         hoverAnimation: true,
    //         data: this.locationData,
    //         label: {
    //           show: true,
    //           position: ["130%", "0"],
    //           fontSize: this.fontSize,
    //           color: this.color,
    //           formatter: params => {
    //             return params.name;
    //           }
    //         },
    //         itemStyle: {
    //           color: this.color
    //         },
    //         emphasis: {
    //           label: {
    //             show: true,
    //             fontSize: this.fontSize + 20,
    //             color: this.option.empColor
    //           },
    //           itemStyle: {
    //             color: this.option.empColor
    //           }
    //         }
    //       }
    //     ]
    //   };
    //   if (this.banner && window.echarts && window.echarts.version && window.echarts.version >= '5.2.2') {
    //     this.myChart.off("mouseover");
    //     this.myChart.off("mouseout");
    //     // this.myChart.off("georoam");

    //     this.myChart.on("mouseover", () => {
    //       clearInterval(self.bannerCheck);
    //       self.resetBanner();
    //     });
    //     this.myChart.on("mouseout", () => {
    //       // console.log(params)
    //       self.bannerCount = 0;
    //       self.setBanner();
    //     });
    //     /*this.myChart.on("georoam", e => {
    //       const option = self.myChart.getOption();
    //       const geo = option.geo[0];
    //       self.centerData = geo.center;
    //       self.zoomData = geo.zoom;
    //       if (self.zoomData < 1) self.zoomData = 1;
    //     });*/
    //   }

    //   if (forceUpdate) {
    //     this.myChart.clear();
    //   }
    //   this.myChart.resize();
    //   this.myChart.setOption(option, true);
    // },
    // updateChart(forceUpdate = false) {
    //   const dataChart = this.dataChart && this.dataChart.length > 0 && this.dataChart[0];
    //   let self = this;
    //   this.$axios(this.computedImgUrl(this.mapData)).then(res => {
    //     const data = res.data;
    //     const optionData = this.deepClone(data);
    //     // 修复数据来源多个的冲突，动态数据优先
    //     self.renderChart(dataChart || optionData, forceUpdate)
    //   })

    // }
  }
});
</script>



