<template>
  <div :class="[className]" :style="styleSizeName" class="visit-chart" style="position: relative;">
    <div class="date-picker">
      <el-date-picker 
        v-model="date" 
        class="visit-date-picker" 
        type="daterange" 
        align="right" 
        unlink-panels range-separator="至"
        start-placeholder="开始日期" 
        @change="changeTime"
        end-placeholder="结束日期" 
        :picker-options="pickerOptions" 
        style="width: 270px">
      </el-date-picker>
    </div>
    <div ref="chart" :style="styleChartName"></div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import {trendData} from '@/api/carPlay.js'

export default {
  name: 'visitChart',
  props: {
    option: Object,
    component: Object
  },
  data() {
    return {
      date: [
        dayjs(new Date()).subtract(6, 'day').format('YYYY-MM-DD'),
        dayjs(new Date()).format('YYYY-MM-DD')
      ],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      chartData: [],
      timeValue: {
        startTime: dayjs(new Date()).subtract(6, 'day').format('YYYY-MM-DD'),
        endTime:  dayjs(new Date()).format('YYYY-MM-DD')
      },
    }
  },
  watch: {
    styleSizeName() {
      this.initChart()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    changeTime(){
      this.timeValue.startTime = dayjs(this.date[0]).format('YYYY-MM-DD')
      this.timeValue.endTime = dayjs(this.date[1]).format('YYYY-MM-DD')
      this.getData()
    },
    initChart() {
      this.myChart = window.echarts.init(this.$refs.chart);
      this.getData()
    },
    getData() {
      let params ={
        startTime : this.timeValue.startTime,
        endTime: this.timeValue.endTime,
        appId: '450244186128',
        // 是否添加秘钥
        isKey: 'true',
        timestamp : new Date().getTime() + "",
        userId: ''
      }
      trendData(params).then(res => {
        if(res.data.success){
          let a = JSON.parse(res.data.data)
          if(a.length > 0) {
            this.chartData = a
            this.updateChart()
          } else {
            this.chartData = []
          }
        } else {
          this.chartData = []
        }
      })
    },
    updateChart() {
      if(!this.myChart) return
      const optionData = this.chartData
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 0,
          left: 0,
          textStyle: {
            color: 'rgba(160, 169, 222, 1)',
            fontSize: 16
          }
        },
        grid: {
          left: 50,
          right: 0
        },
        xAxis: {
          type: 'category',
          data: optionData.map(item => item.date),
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ['#2D3C8A'],
              type: 'dashed',
            }
          },
          axisLabel: {
            color: '#76B5FD',
            formatter: (params, index) => {
              if(index === optionData.length - 1) {
                return `{end|${params}}`
              }
              if(index === 0) return `{left|${params}}`
              return params
            },
            rich: {
              end: {
                align: 'left',
                width: 140
              },
              left: {
                align: 'right',
                width: 120
              }
            }
          }
        },
        yAxis: {
          axisLabel: {
            show: true,
            color: '#76B5FD'
          },
          axisLine: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#2D3C8A']
            }
          }
        },
        series: [
          {
            name: '浏览量PV',
            type: 'line',
            data: optionData.map(item => item.visitNum),
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#007BE2' // 0% 处的颜色
                },
                {
                  offset: 0.19, color: 'rgba(0, 134, 226, 0.71)' // 100% 处的颜色
                }, {
                  offset: 1, color: 'rgba(0, 162, 226, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            lineStyle: {
              color: 'rgba(1, 236, 255, 1)'
            },
            symbol: 'circle',
            itemStyle: {
              color: 'rgba(1, 236, 255, 1)'
            }
          },
          {
            name: '访客数UV',
            type: 'line',
            data: optionData.map(item => item.userNum),
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(51, 72, 218, 1)' // 0% 处的颜色
                },
                {
                  offset: 0.67, color: 'rgba(91, 88, 255, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            lineStyle: {
              color: 'rgba(166, 187, 255, 1)'
            },
            symbol: 'circle',
            itemStyle: {
              color: 'rgba(166, 187, 255, 1)'
            }
          }
        ]
      };
      this.myChart.resize();
      this.myChart.setOption(option, true);
    }
  }
}
</script>
<style lang="scss" scoped>
.date-picker {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
::v-deep .visit-date-picker .el-range-input {
    background-color: transparent !important;
    color: #B1C7DE !important;
}
::v-deep .visit-date-picker .el-range-separator {
  color: #fff !important;
}
::v-deep .visit-date-picker.el-input__inner {
  background: rgba(0, 10, 53, 0.01) !important;
  border: 1px solid #214E92 !important;
  box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
  border-radius: 2px;
}
::v-deep .visit-date-picker .el-range__icon {
  position: absolute;
  background-color: #455DEA;
  right: 0;
  width: 36px;

}
</style>