<template>
  <div>
    <div class="tab-box">
      <div class="custom-tabs">
        <div class="tab-item" :class="{ active: item.value === tabActive }" @click="tabActive = item.value"
          v-for="item in tabs" :key="item.value">{{ item.title }}</div>
      </div>
    </div>
    <div class="avue-echart-table" style="margin-top: 30px">
      <el-table ref="table" :data="tableData" :height="650" :header-row-style="headerRowStyle"
      v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)"
        :header-cell-style="headerCellStyle" :cell-style="cellStyle" :row-style="rowStyle">
        <el-table-column show-overflow-tooltip prop="title" label="活动" width="130px">
          <template slot-scope="{row}">
            <div class="cell-product-title">
              <span style="color: #76B5FD">{{ row.dynamic_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="info" label="动态信息">
          <template slot-scope="{row}">
            <div style="color: #B1C7DE; font-weight: bold;">
              {{row.shop_name}}发布了
              <span v-if="row.dynamic_type === 'SECKILL'" style="color: #01ECFF">秒杀</span>
              <span v-if="row.dynamic_type === 'GROUP_ACTIVITY'" style="color: #F56C6C">拼团</span>
              <span v-if="row.dynamic_type === 'DISCOUNT'" style="color: #E79232">满减满折</span>
              活动
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="time" label="时间" width="150px">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { setPx } from '../../echart/util'
import request from '../../axios'
export default {
  name: 'dynamicItem3',
  data() {
    return {
      headerHeight: '',
      tabs: [
        { title: '全部', value: 0 },
        { title: '秒杀', value: 'SECKILL' },
        { title: '拼团', value: 'GROUP_ACTIVITY' },
        { title: '满减满折', value: 'DISCOUNT' },
      ],
      tabActive: 0,
      list: [],
      headerRowStyle: {
        background: '#2B3F84'
      },
      headerCellStyle() {
        return {
          fontSize: 14,
          backgroundColor: '#2B3F84',
          color: '#FFFFFF',
          // textAlign: column.type == 'index' ? 'center' : this.option.headerTextAlign
        }
      },

      scroll: true,
      scrollTime: 2000,
      scrollSpeed: 5,
      count: 8,
      host: 'https://goat-api.sjxfbf.com'
    }
  },
  created() {
    this.$nextTick(() => {
      this.height = 650
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      setTimeout(() => {
        this.setTime();
      }, 3000)
    })
  },
  computed: {
    cellHeight() {
      return parseInt((650 - this.headerHeight) / this.count)
    },
    tableData() {
      if (this.tabActive === 0) return this.list
      return this.list.filter(item => item.dynamic_type === this.tabActive)
    }

  },
  mounted() {
    this.getData()
    setInterval(() => {
      this.getData()
    }, 1000 * 60 * 5)
  },
  methods: {
    setTime() {
      clearInterval(this.scrollCheck);
      this.height = 650
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      const table = this.$refs.table
      const divData = table.bodyWrapper
      const speed = this.scrollSpeed
      let top = 0
      if (this.scroll) {
        this.scrollCheck = setInterval(() => {
          top = top + speed
          divData.scrollTop += speed;
          if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
            divData.scrollTop = 0
          }
          if (top >= this.cellHeight && this.scrollTime) {
            divData.scrollTop = divData.scrollTop - (top - this.cellHeight)
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              this.setTime()
            }, this.scrollTime)
          }
        }, 20)
      } else {
        divData.scrollTop = 0
      }
    },
    rowStyle() {
      return {
        backgroundColor: 'transparent'
      }
    },
    cellStyle() {
      return {
        borderWidth: '0px 0px 1px 0px',
        borderStyle: 'dashed',
        borderColor: '#2D3C8A',
        backgroundColor: 'transparent',
        color: '#B1C7DE',
        padding: '24px 0',
        boxSizing: 'border-box',
        height: setPx(this.cellHeight),
      }
    },
    getData(dynamic_type) {
      this.dynamic_type = dynamic_type
      const params = {
        limitOffset: 0,
        limitRowCount: 15,
      }
      if (dynamic_type) {
        params.dynamic_type = dynamic_type
      }
      this.loading = true
      request(`${this.host}/api/system/DataInterface/451388377200094853/Actions/Response`, {
        params,
        headers: {
          Authorization: 'nRxlROHkk8YzWQwotBFnHj4getcWtYTQ::871154b1f92640f199a30017fa73b822',
          ymDate: new Date().getTime()
        }
      }).then(res => {
        this.loading = false
        this.$emit('load')
        if (res.data.code === 200) {
          this.list = res.data.data.map(item => ({
            _id: new Date().getTime(),
            ...item,
            time: dayjs(item.dynamic_time).format('YYYY.MM.DD HH:MM'),
          }))
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab-box {
  display: flex;
  justify-content: space-between;
}

.top-right {
  color: #01ECFF;
  font-size: 14px;
}

.cell-product-img {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  margin-right: 5px;
}

.cell-product-title {
  display: flex;
  align-items: center;
}

.tab-box {
  display: flex;
  align-items: center;
}

.custom-tabs {
  display: flex;
  align-items: center;

  .tab-item {
    min-width: 44px;
    height: 28px;
    border-radius: 2px;
    padding: 4px 8px;
    margin-right: 8px;
    color: #76B5FD;
    text-align: center;
    cursor: pointer;
    line-height: 28px;
  }

  .active {
    background: #455DEA;
    color: #fff;
  }
}</style>