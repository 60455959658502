<template>
  <div id="map-container" :style="{
    'width': `${component.width}px`, 
    'height': `${component.height}px`, 
    'display': 'flex', 
    'flex-direction':'row'
    }">
    <div class="echarts"  >
      <div id="map" v-show="!isError" ></div>
    </div>
    <div class="box">
      <div style="height:40px" class="box-search">
        <el-cascader
          v-model="areaCodes"
          clearable
          ref="cascader"
          class="elCascader"
          placeholder="请选择地区"
          @change="changeFun('cascader')"
          :props="provinceProps"
        ></el-cascader>    
        <div @click.stop="search" class="search-sub" >
              <i class="el-icon-search" :size="20" ></i>
            </div>
      </div>
      <div class="box-list">
        <div class="box-nav" v-if="deepTree.length > 0">
          <span v-for="(e, i) in deepTree" :class="{'end': i === deepTree.length - 1}" :key="e.params.adcode" @click="goback(e, i)">
            &nbsp;{{   `${e.params.level ==='province' ? ''  : ' / '}${e.params.name}${e.params.level ==='province' ? '省'  : ''}` }}
          </span>
          <i class="el-icon-caret-right"></i>
        </div>
        <div class="box-content">
          <div v-for="(e, i) in dataNum" :key="i" class="box-item">
            <span class="span"></span>
            {{ e.name }}
            <span class="value">{{ e.value ? e.value: 0 }} 条</span>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
  import { mapOption } from '@/utils/mapOption'
  import resize from '@/utils/resize'
  import {china, province, city, county, AreaCode, Areadata} from '@/api/map.js'

  export default {
    mixins: [resize],
    name: 'mapList',
    components: {},
    props: {
      areaCode: {
        type: String,
        default: '140000'
      },
      areaLevel: {
        type: Number,
        default: 1
      },
      areaName: {
        type: String,
        default: '山西'
      },
      option: Object,
      component: Object
    },
    watch:{
      component:{
        deep: true,
        handler(a){
          if(a.width != 800 || a.height != 500) {
            this.$nextTick(()=>{
              this.chart.resize()
            })
          }
         
        }
      }
    },
    data() {
      return {
        provinceProps: {
          expandTrigger: 'hover',
          value: 'enCode',
          children: 'children',
          label: 'fullName',
          checkStrictly: true, //添加这个可以选择每一层级，不添加这个只能选择最后一级
          lazy: true,
          lazyLoad: this.getPosition
        },
        chart: null, // 实例化echarts
        mapDataList: [], // 当前地图上的地区
        options: { ...mapOption.basicOption }, // map的相关配置
        deepTree: [], // 点击地图时push，点返回时pop
        areaStatisticMapValue: {}, // 地图数据value, 只是amounts
        areaStatisticMapData: {}, // 地图数据data,包含所有数据
        areaLevelMap: {
          country: 0,
          china: 0,
          province: 1,
          city: 2,
          district: 3
        },
        isError: false,
        areaCodes: [],
        record: [],
        dataNum:[
          {
            name: '供应信息',
            code: 'SAD_SUPPLY',
            value: ''
          },
          {
            name: '本地风景',
            code: 'APPEARANCE',
            value: ''
          },
          {
            name: '求购信息',
            code: 'SAD_DEMAND',
            value: ''
          },
          {
            name: '本地特产',
            code: 'SPECIALITY',
            value: ''
          },
          {
            name: '求助信息',
            code: 'SEEK_HELP',
            value: ''
          },
          {
            name: '站内公告',
            code: 'NOTICE',
            value: ''
          },
          {
            name: '求助回答',
            code: 'SEEK_HELP_ANSWER',
            value: ''
          },
          {
            name: '新闻头条',
            code: 'NEWS',
            value: ''
          },
          {
            name: '招工信息',
            code: 'LABOR_RECRUIT',
            value: ''
          },
          {
            name: '政务公开',
            code: 'POLICY',
            value: ''
          },
          {
            name: '找活人数',
            code: 'LABOR_WORKER',
            value: ''
          },
          {
            name: '招商引资',
            code: 'INVESTMENT',
            value: ''
          },
        ]
      }
    },
    beforeDestroy() {
      if (!this.chart) {
        return
      }
      this.chart.dispose()
      this.chart = null
    },
    mounted() {
      this.$nextTick(() => {
        this.initEcharts()
        this.chart.on('click', this.echartsMapClick)
      })
    },
    methods: {
      getlist(code, i) {
        let params = {
          areaCodeLikeRight: (code +'').substr(0, i * 2)
        }
        Areadata(params).then(res => {
          if(res.data.code === 200) {
            this.dataNum.forEach(e => {
              e.value = res.data.data[e.code]
            })
          }
        })
      },
      // 地区联动获取数据
      getPosition(node, resolve) {
        let callback = () => {
          resolve(
            [].map(ele => {
              return Object.assign(ele, {
                leaf: true
              })
            })
          )
        }

        if (node.data && node.data.leaf) {
          callback()
          return
        }
        AreaCode(node.level === 0 ? { parentEnCode: '14' } : { parentEnCode: node.value }).then(res => {
          if (node.level === 1) {
            res.data.data.list.forEach(e => {
              e.leaf = true
            })
          }
          resolve(res.data.data.list)
        })
      },
      // 地区下拉框隐藏
      changeFun(type) {
        this.$refs[type].dropDownVisible = false
      },
      // 地区查询
      async search(){
        if(this.areaCodes.length === 0) return
        this.isError = false
        // 获取选中的级联数据
        let a = this.$refs['cascader'].getCheckedNodes()[0]
        // 搜索清空下钻的地址
        this.deepTree = [this.deepTree.shift()]
        // 下钻地区时 获取城市的数据， 放入下钻地址中
        if(this.areaCodes.length == 2) {
          let res = await city(this.areaCodes[0]+'00')
          let params = { name: a.parent.label, level: 'city', adcode: this.areaCodes[0]+'00' }
          window.echarts.registerMap(params.name, res.data)
          await this.setTree(res.data, params)
        }
        this.getdata( this.areaCodes.length + 1,  {
            name:  a.label,
            level: this.areaCodes.length + 1  === 2 ? 'city' : 'district',
            adcode: (this.areaCodes[this.areaCodes.length - 1] + '000').substr(0, 6)
          })
      },
      setTree(res, params){
        var mapDataList = []
        for (var i = 0; i < res.features.length; i++) {
          var obj = {
            ...res.features[i].properties,
            value: this._mathRandom1000(),
            valueData: this._mathRandom1000()
          }
          mapDataList.unshift(obj)
        }
        this.deepTree.splice(1, 0, { mapDataList: mapDataList, params: params })
      },
      // 获取静态数据
      async getdata(type, params) {
        try {
          let res = null
          if (type === 0) {
            res = await china()
            this.setJsonData(res.data, params)
          } else if (type === 1) {
            res = await province(params.adcode)
            this.setJsonData(res.data, params)
          } else if (type === 2) {
            res = await city(params.adcode)
            this.setJsonData(res.data, params)
          } else if (type === 3) {
            res = await county(params.adcode)
            this.setJsonData(res.data, params)
          }
          // 点击事件传递数据
          this.getlist(params.adcode, type)
        } catch (error) {
          this.isError = true
        }
      },
      // 初次加载绘制地图
      initEcharts() {
        //地图容器
        window.echarts.dispose(document.getElementById('map'))
        this.chart = window.echarts.init(document.getElementById('map'))
        if (this.areaLevel === 0) {
          this.getdata(0)
        } else if (this.areaLevel === 1) {
          this.getdata(this.areaLevel, {
            name: this.areaName,
            level: 'province',
            adcode: this.areaCode.substr(0, 6)
          })
        } else if (this.areaLevel === 2) {
          this.getdata(this.areaLevel, {
            name: this.areaName,
            level: 'city',
            adcode: this.areaCode.substr(0, 6)
          })
        } else if (this.areaLevel === 3) {
          this.getdata(this.areaLevel, {
            name: this.areaName,
            level: 'district',
            adcode: this.areaCode.substr(0, 6)
          })
        } else {
          return false
        }
      },
      // 地图点击
      echartsMapClick(params) {
        this.areaCodes = []
        if (params.data.level === 'province') {
          this.getdata(1, params.data)
        } else if (params.data.level === 'city') {
          this.getdata(2, params.data)
        } else if (
          params.data.level === 'district' &&
          this.mapDataList.length > 1 &&
          !this.deepTree.map(e => e.params.adcode).includes(params.data.adcode)
        ) {
          // 判断deeptree下钻储存的数据中是否有重复储存 this.deepTree.map(e => e.params.adcode).includes(params.data.adcode)
          this.getdata(3, params.data)
        } else {
          return false
        }
      },
      // 设置数据
      setJsonData(res, params) {
        var mapDataList = []
        for (var i = 0; i < res.features.length; i++) {
          var obj = {
            ...res.features[i].properties,
            value: this._mathRandom1000(),
            valueData: this._mathRandom1000()
          }
          mapDataList.unshift(obj)
        }
        this.mapDataList = mapDataList
        this.setMapData(res, params)
      },
      // 设置地图信息
      setMapData(res, params) {
        if (this.areaName === 'china') {
          this.deepTree.push({
            mapDataList: this.mapDataList,
            params: { name: 'china', level: 'country', adcode: '100000' }
          })
          //注册地图
          window.echarts.registerMap('china', res)
          //绘制地图
          this.renderMap({ name: 'china', level: 'country', adcode: '100000' }, this.mapDataList)
        } else {
          this.deepTree.push({ mapDataList: this.mapDataList, params: params })
          window.echarts.registerMap(params.name, res)
          this.renderMap(params, this.mapDataList)
        }
      },
      // 渲染地图
      renderMap(map, data) {
        var mapDataList = data.map(item => {
          return {
            name: item.name,
            value: item.value
          }
        })
        mapDataList = mapDataList.sort(function (a, b) {
          return b.value - a.value
        })
        var pointData = []
        for (var i = 0; i < data.length; i++) {
          if (data[i].value != 0) {
            pointData.push({
              ...data[i],
              value: [data[i].center[0], data[i].center[1], data[i].value]
            })
          }
        }
        // 设置左下角数量范围值
        this.options.visualMap.min = mapDataList.length > 1 ? mapDataList[mapDataList.length - 2].value : 0
        this.options.visualMap.max = mapDataList.length > 0 ? mapDataList[0].value : 0
        // 设置左上角当前位置
        // this.option.title[0].text = map === "china" ? "全国" : map;
        this.options.geo = {
          show: false,
          map: map.name,
          zoom:  1, //当前视角的缩放比例
          roam: false, //是否开启平游或缩放
          center: undefined
        }
        this.options.series = [
          {
            name: map.name,
            mapType: map.name,
            zoom:  1, //当前视角的缩放比例
            roam: false, //是否开启平游或缩放
            center: undefined,
            scaleLimit: {
              //滚轮缩放的极限控制
              min:  map.level === 'province' ? 1.2 : 1,
              max:  map.level === 'province' ? 1.2 : 1
            },
            ...mapOption.seriesOption,
            data: data
          },
          {
            name: '散点', //series名称
            type: 'effectScatter', //散点类型
            coordinateSystem: 'geo', // series坐标系类型
            rippleEffect: {
              brushType: 'fill'
            },
            normal: {
              show: false,
              // 提示内容
              formatter: params => {
                return params.name
              },
              position: 'top', // 提示方向
              color: '#fff'
            },
            emphasis: {
              show: true // 点
            },
            itemStyle: {
              normal: {
                color: '#F4E925',
                shadowBlur: 10,
                shadowColor: '#000'
              }
            },
            // symbol:'pin', // 散点样式'pin'（标注）、'arrow'（箭头）
            data: pointData,
            symbolSize: function (val) {
              // return val[2] / 100;
              if (val[2] === mapDataList[0].value) {
                return 10
              }
              return 8
            },
            showEffectOn: 'render' //加载完毕显示特效
          }
        ]
        //渲染地图
        this.chart.setOption(this.options, true)
      },
      // 随机数
      _mathRandom1000() {
        return (Math.random() * 1000).toFixed(0) * 1
      },
      // 返回
      goback(e, i){
            this.isError = false
            this.areaCodes = []
            this.deepTree = this.deepTree.slice(0, i + 1)
            this.renderMap(e.params, e.mapDataList)
            // 点击事件传递数据
            this.getlist(e.params.adcode, i + 1)
      },
    }
  }
</script>

<style scoped lang="scss">
  #map-container {
    height: 100%; 
    position: relative;
  }
  .echarts {
    width: 67%;
    height: 100%;
  }
  .box{
    width: 33%;
    height: calc(100% - 40px);
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    .box-search{
      display: flex;
      flex-direction: row;
      .elCascader{
        flex: 1;
        ::v-deep .el-input__inner{
          background: #000A35 !important;
          border: 1px solid #214E92 !important;
          box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
        }
      }
   
      .search-sub{
        width: 45px; 
        height: 40px; 
        line-height: 40px; 
        text-align: center; 
        display: inline-block;
        background-color: #61A8FF !important;
        color: #3F4DE5 !important;
        padding: 0;
      }
    }
    .box-list{
      color: #fff;
      flex:1;
      margin-top: 30px;
      border: 1px solid #214E92;
      border-radius: 10px;
      cursor: auto;
      display: flex;
      flex-direction: column;
      .box-nav{
        position: relative;
        padding: 16px 25px;
        span{
          font-size: 18px;
          color: #214E92;
          display: inline-block;
          cursor: pointer;
          font-weight: bold;
        }
        .end{
          color: #01ECFF;
        }
      }
      .el-icon-caret-right{
        position: absolute;
        font-size: 30px;
        color: #02EBFF;
        left: -12px;
        top: 13px;
      }
      .box-content{
        
        padding: 0 25px;
        padding-top: 20px;
        flex: 1;
        overflow-y: hidden;
        display: flex;
        flex-wrap: wrap;
        .box-item {
          width: 49%;
          height: 30px;
          font-size: 14px;
          color: #76B5FD;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .span{
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #76B5FD;
          border-radius: 50%;
          margin-right: 5px;
        }
        .value{
          margin-left: 8px;
          font-size: 16px;
          font-weight: bold;
          color: #01ECFF;
        }
      }
    }
  }
  #map {
    width: 100%;
    height: 100%;
  }
  .back {
    position: absolute;
    border: 0;
    background-color: #fff;
    top: 55px;
    left: 5px;
    z-index: 9;
    font-weight: bolder;
    cursor: pointer;
  }
</style>
