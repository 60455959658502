const APIURl = 'https://goat-api.sjxfbf.com'

const DEVURL = 'http://10.10.1.29:30000'
// 电商商城ip
const SHOPURI = 'https://platform-api.sjxfbf.com'
// const SHOPURI = 'http://10.10.1.95:8114'

const IMGAPI = 'https://static.sjxfbf.com/'
module.exports = {
    APIURl: APIURl,
    SHOPURI,
    IMGAPI,
    DEVURL
}
