
const apiConfig = require('./config');
import request from '../axios'




// 实时数据
export const realTime = params => {
  return request({
    url: `${apiConfig.shopURI}/platform/statistics/platformRealTimeOverview`,  
    method: 'get',
    params,
  })
}


// 热卖店铺
export const hotStore = params => {
  return request({
    url: `${apiConfig.shopURI}/platform/statistics/shop`,  
    method: 'get',
    params,
  })
}

// 热卖商品

export const hotGoods = params => {
  return request({
    url: `${apiConfig.shopURI}/platform/statistics/prod`,  
    method: 'get',
    params,
  })
}



// 交易数据

export const trendData = params => {
  return request({
    url: `${apiConfig.shopURI}/platform/statistics/trendData`,  
    method: 'get',
    params,
  })
}