<!-- 自定义配置 -->
<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'mapList',
  inject: ["main"]
}
</script>

<style>
</style>
