<template>
  <div :style="styleSizeName" :class="className">
    <div class="top-bar">
      <div class="product-tabs">
        <div v-for="item in tabs" :key="item.value" class="tab-item" :class="{ active: activeTab === item.value }"
          @click="() => {
              activeTab = item.value
              changeTime()
            }">{{ item.name }}</div>
      </div>
      <div>
        <el-date-picker 
            class="date-picker"
            v-model="value"
            :type="activeTab"
            :format="activeTab === 'week' ? 'yyyy 第 WW 周' : '' "
            @change="changeTime"
            placeholder="选择日期"
          >
        </el-date-picker>
      </div>
    </div>
    <div class="custom-swiper" :style="styleSizeName">
      <div class="swiper-container" ref="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-item" v-for="(item, index) in dataChart" :key="item.id">
              <div class="product-rank" :style="{opacity: productActive === index ? '1' : 0}">TOP{{ index + 1 }}</div>
              <div class="product-line" :style="{opacity: productActive === index ? '1' : 0}"></div>
              <div class="product-statistics" :style="{opacity: productActive === index ? '1' : 0}">
                <div>销量：<span>{{ item.sales ? item.sales : 0 }}单</span></div>
                <div>收款金额：<span>{{Number(item.amount) | getlimit}}</span></div>
              </div>
              <div class="product-img">
                <div class="product-img_inner">
                  <img :src="`${IMGAPI}${item.pic}`" alt="">
                  <div class="product-name">{{ item.title }}</div>
                </div>
              </div>
            </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {hotGoods} from '@/api/carPlay.js'
import {StartEndTime} from '@/utils/utils.js'
import {unitConvert} from '@/utils/utils.js'
import {IMGAPI} from'@/utils/define'

export default {
  name: 'productsTop10',
  data() {
    return {
      IMGAPI,
      tabs: [
        {
          name: '按日',
          value: 'date'
        },
        {
          name: '按周',
          value: 'week'
        },
        {
          name: '按月',
          value: 'month'
        },
        {
          name: '按年',
          value: 'year'
        }
      ],
      activeTab: 'year',
      value: new Date(dayjs().subtract(1, 'year')),
      timeValue: {
        startTime:'',
        endTime: ''
      },
      productActive: 0,
      myswiper: null,
      slides: [],
      list: []
    }
  },
  props: {
    option: Object,
    component: Object
  },
  watch: {
    styleSizeName() {
      this.initSwiper()
    },
    dataChart() {
      this.initSwiper()
    }

  },
  filters:{
    getlimit(e){
      let a = unitConvert(e)
      return `${a.num}${a.unit}`
    }
  },
  mounted() {
    this.changeTime()
  },
  methods: {
    initSwiper() {
      this.$nextTick(() => {
        const width = parseFloat(this.styleSizeName.width) / 1.5;
        if (this.myswiper) {
          this.myswiper.destroy()
        }
        this.myswiper = new window.Swiper(this.$refs.swiper, {
          initialSlide: 0,
          watchSlidesProgress: true,
          slidesPerView: 3,
          // loopAdditionalSlides: 5,
          // slidesPerView : 'auto',
          centeredSlides: true,
          loop: true,
          loopedSlides: 5,
          autoplay: true,
      
          on: {
            progress: function () {
              for (let i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                let modify = 1;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.5 + 1;
                }
                let translate = -(slideProgress * modify * (width / 2 - 220)) + "px";
                let scale = 1 - Math.abs(slideProgress) / 2.5;
                let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform(
                  "translateX(" + translate + ") scale(" + scale + ")"
                );
                slide.css("zIndex", zIndex);
                // slide.css("opacity", scale);
                // if (Math.abs(slideProgress) > 3) {
                //   slide.css("opacity", 0);
                // }
              }
            },
            setTransition: function (transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                slide.transition(transition);
              }
            },
            slideChange:() => {
              if(this.myswiper) {
                this.productActive = this.myswiper.realIndex
              }
            },
          },
        });
      });
    },
    changeTime() {
      this.timeValue = StartEndTime(this.value, this.activeTab)
      this.getlist()
    },
    getlist() {
      let params ={
        startTime : this.timeValue.startTime,
        endTime: this.timeValue.endTime,
        appId: '450244186128',
        // 是否添加秘钥
        isKey: 'true',
        timestamp : new Date().getTime() + "",
        userId: ''
      }
      hotGoods(params).then(res => {
        if(res.data.success){
          let a = JSON.parse(res.data.data)
          if(a.length > 0) {
            this.dataChart = a
          } else {
            this.dataChart = []
          }
        } else {
          this.dataChart = []
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.product-tabs {
  display: flex;
  align-items: center;

  .tab-item {
    width: 44px;
    height: 28px;
    border-radius: 2px;
    padding: 4px 8px;
    margin-right: 8px;
    color: #76B5FD;
    text-align: center;
    cursor: pointer;
    line-height: 28px;
  }

  .active {
    background: #455DEA;
    color: #fff;
  }
}
  .date-picker{
    cursor: pointer;
  }
::v-deep .date-picker .el-input__inner {
  cursor: pointer;
  padding-left: 15px;
  background: rgba(0, 10, 53, 0.01) !important;
  border: 1px solid #214E92 !important;
  box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
  color: #B1C7DE !important;
  border-radius: 2px;
}

::v-deep .date-picker .el-input__prefix {
  right: 0;
  left: auto;
  width: 40px;
  background: #455DEA;
  box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
  color: #fff;
}

::v-deep .date-picker .el-input__suffix {
  right: 45px;
}
.custom-swiper {
  margin-top: -10px;
}
.swiper-item {
  width: auto;
  padding-bottom: 150px;
  background: url('./bg.png') no-repeat center bottom;

}

.product-rank {
  color: #01ECFF;
  font-size: 26px;
  text-align: center;
  height: 38px;
  line-height: 38px;
}

.product-line {
  background: radial-gradient(50% 50% at 50% 50%, #01ECFF 0%, rgba(255, 255, 255, 0.00) 100%);
  width: 153px;
  height: 3px;
  margin: 3px auto;
}

.product-statistics {
  display: flex;
  justify-content: space-between;
  color: #76B5FD;
  margin-top: 11px;
  font-size: 14px;

  span {
    color: #01ECFF;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }
}

.product-img {
  border: 5px solid #214E92;
  box-shadow: 1px 6px 75px 13px rgba(97, 168, 255, 0.3);
  border-radius: 12px;
  width: 230px;
  height: 230px;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 25px;
  overflow: hidden;

  .product-img_inner {
    position: relative;

    img {
      border-radius: 12px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      box-sizing: border-box;
    }

    .product-name {
      position: absolute;
      bottom: 0;
      left: 0;
      color: #B1C7DE;
      font-size: 14px;
      background: rgba(0, 10, 63, 0.84);
      padding: 6px 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0px 0px 12px 12px;
    }
  }
}</style>
