import { validatenull } from '@/echart/util'
import dayjs from "dayjs";

export const uuid = () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}
export const createFile = () => {
    return {
        "title": "文件夹",
        "name": "文件夹",
        "index": uuid(),
        "children": []
    }
}
export const compare = (propertyName) => {
    return function (object1, object2) {
        var value1 = object1[propertyName];
        var value2 = object2[propertyName];
        if (value2 < value1) {
            return -1;
        } else if (value2 > value1) {
            return 1;
        } else {
            return 0;
        }
    }
}

export const funEval = (value) => {
    return new Function("return " + value + ";")();
}


export const getFunction = (fun, def) => {
    if (!validatenull(fun)) {
        try {
            return funEval(fun)
        } catch {
            return () => {
            }
        }
    } else if (def) return () => {
    }
}
export const getJson = (str) => {
    if (validatenull(str)) return {};
    else if (typeof str == "string") {
        try {
            return JSON.parse(str);
        } catch {
            return {}
        }
    } else {
        return str;
    }
}
export const checkUrl = (url) => {
    var reg = /http(s)?:\/\/([\w-.]+)+(:[0-9]+)?.*$/;
    if (!reg.test(url)) {
        return false;
    } else {
        return true
    }
}
export const loadScript = (type = 'js', url, dom = "body") => {
    let flag = false;
    return new Promise((resolve) => {
        const head = dom == 'head' ? document.getElementsByTagName('head')[0] : document.body;
        for (let i = 0; i < head.children.length; i++) {
            let ele = head.children[i]
            if ((ele.src || '').indexOf(url) !== -1) {
                flag = true;
                resolve();
            }
        }
        if (flag) return;
        let script;
        if (type === 'js') {
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
        } else if (type === 'css') {
            script = document.createElement('link');
            script.rel = 'stylesheet';
            script.type = 'text/css';
            script.href = url;
        }
        head.appendChild(script);
        script.onload = function () {
            resolve();
        };
    });
};
export const getUrlParam = name => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    } else {
        return null;
    }
}
export const isLocalUrl = (url) => {
    let local = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
    let reg = new RegExp(`^(${local})\\S*`);
    return reg.test(url);
}
export const computedUrl = (baseUri, imgUri, staticUri) => {
    if (/^(http|https):\/\/([\w.]+\/?)\S*/.test(imgUri)) {
        return imgUri;
    }
    if (/^(data)\S*/.test(imgUri)) {
        return imgUri;
    }
    if (/^\/(DataV)\S*/.test(imgUri)) {
        return imgUri;
    }
    if (/^\/(img)\S*/.test(imgUri)) {
        return staticUri + imgUri;
    }
    if (/^\/(api)\S*/.test(imgUri)) {
        return baseUri + imgUri;
    }
    if (/^\/(dev)\S*/.test(imgUri)) {
        return imgUri;
    }
    return imgUri;
}


export const unitConvert = (num) => {
    var moneyUnits = ["", "万", "亿", "万亿"] 
    var dividend = 10000;
    var curentNum = num;
    //转换数字 
    var curentUnit = moneyUnits[0];
    //转换单位 
    for (var i = 0; i <4; i++) { 
        curentUnit = moneyUnits[i] 
        if(strNumSize(curentNum)<5){ 
            break;
        }
        curentNum = curentNum / dividend 
    } 
    var m = {num: 0, unit: ""} 
    m.num = curentNum.toFixed(2)
    m.unit = curentUnit;
    return m;
}
 
function strNumSize(tempNum){ 
    var stringNum = tempNum.toString() 
    var index = stringNum.indexOf(".") 
    var newNum = stringNum;
    if(index!=-1){
        newNum = stringNum.substring(0,index) 
    } 
    return newNum.length
}

/***
 * 年月日的开始时间结束时间
 * 
 * **/ 
export const StartEndTime = (t, y)  =>{
    if (!t)  t = new Date()
    let e = {
      startTime : '',
      endTime: ''
    }
    // 日
    if(y === 'date' || y === 'dates') {
        e.startTime = dayjs(t).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        e.endTime = dayjs(t).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        return e
      } else if(y === 'week'|| y === 'weeks') {
        // 判断是否是默认数据
        if(dayjs(dayjs(t).format('YYYY-MM-DD')).isSame(dayjs(dayjs(new Date()).format('YYYY-MM-DD')))){
          t = dayjs(t).day(1).format('YYYY-MM-DD')
        }
        e.startTime = dayjs(t).subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
        e.endTime = dayjs(t).add(5, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')
        return e
      } else if(y === 'month' || y === 'months') {
        e.startTime = dayjs(t).startOf('month').format('YYYY-MM-DD HH:mm:ss')
        e.endTime = dayjs(t).endOf('month').format('YYYY-MM-DD HH:mm:ss')
        return e
      } else if(y === 'year' || y === 'years') {
        e.startTime = dayjs(t).startOf('year').format('YYYY-MM-DD HH:mm:ss')
        e.endTime = dayjs(t).endOf('year').format('YYYY-MM-DD HH:mm:ss')
        return e
      } else {
        return 
      }

}
