<template>
  <div>
    <div class="tab-box">
      <div class="custom-tabs">
        <div class="tab-item" :class="{ active: item.value === tabActive }" @click="changeTab(item)" v-for="item in tabs"
          :key="item.value">{{ item.title }}</div>
      </div>
    </div>
    <div class="avue-echart-table" style="margin-top: 30px">
      <el-table ref="table" :data="tableData" :height="650" :header-row-style="headerRowStyle" row-key="_id"
        v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)" :header-cell-style="headerCellStyle"
        :cell-style="cellStyle" :row-style="rowStyle">
        <el-table-column show-overflow-tooltip prop="title" label="掌门" width="100px">
          <template slot-scope="{row}">
            <div class="cell-product-title">
              <img :src="`${row.creatorUserInfo.headIcon}`" class="cell-img">
              <span style="color: #76B5FD" v-if="row.creatorUserInfo && row.creatorUserInfo.realName">{{ row.creatorUserInfo.realName.slice(0, 1) + '先生' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="info" label="动态信息">
          <template slot-scope="{row}">
            <div style="color: #B1C7DE; font-weight: bold;">
              发布了{{ row.areaNamePath[row.areaNamePath.length - 1] }}的
              <span style="color: #01ECFF">{{ tabs.find(item => item.value === row.dynamicType).title }}</span>

            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="time" label="时间" width="150px">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { setPx } from '../../echart/util'
import request from '../../axios'
export default {
  name: 'dynamicItem4',
  data() {
    return {
      headerHeight: '',
      tabs: [
        // 动态类型，不传默认查询所有。APPEARANCE:地区容貌、SPECIALITY:本地特产、NOTICE:公告、NEWS:新闻、POLICY:政务公开、INVESTMENT:招商引资
        { title: '全部', value: 0 },
        { title: '本地风景', value: 'APPEARANCE' },
        { title: '本地特产', value: 'SPECIALITY' },
        { title: '站内公告', value: 'NOTICE' },
        { title: '新闻头条', value: 'NEWS' },
        { title: '政务公开', value: 'POLICY' },
        { title: '招商引资', value: 'INVESTMENT' },
      ],
      tabActive: 0,
      list: [],
      loading: false,
      headerRowStyle: {
        background: '#2B3F84'
      },
      headerCellStyle() {
        return {
          fontSize: 14,
          backgroundColor: '#2B3F84',
          color: '#FFFFFF',
          // textAlign: column.type == 'index' ? 'center' : this.option.headerTextAlign
        }
      },

      scroll: true,
      scrollTime: 2000,
      scrollSpeed: 5,
      count: 8,
      host: 'https://goat-api.sjxfbf.com'
    }
  },
  created() {
    this.$nextTick(() => {
      this.height = 650
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      setTimeout(() => {
        this.setTime();
      }, 3000)
    })
  },
  computed: {
    cellHeight() {
      return parseInt((650 - this.headerHeight) / this.count)
    },
    tableData() {
      if (this.tabActive === 0) return this.list
      return this.list.filter(item => item.dynamicType === this.tabActive)
    }

  },
  mounted() {
    this.getData()
  },
  methods: {
    setTime() {
      clearInterval(this.scrollCheck);
      this.height = 650
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      const table = this.$refs.table
      const divData = table.bodyWrapper
      const speed = this.scrollSpeed
      let top = 0
      if (this.scroll) {
        this.scrollCheck = setInterval(() => {
          top = top + speed
          divData.scrollTop += speed;
          if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
            divData.scrollTop = 0
          }
          if (top >= this.cellHeight && this.scrollTime) {
            divData.scrollTop = divData.scrollTop - (top - this.cellHeight)
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              this.setTime()
            }, this.scrollTime)
          }
        }, 20)
      } else {
        divData.scrollTop = 0
      }
    },
    rowStyle() {
      return {
        backgroundColor: 'transparent'
      }
    },
    cellStyle() {
      return {
        borderWidth: '0px 0px 1px 0px',
        borderStyle: 'dashed',
        borderColor: '#2D3C8A',
        backgroundColor: 'transparent',
        color: '#B1C7DE',
        padding: '24px 0',
        boxSizing: 'border-box',
        height: setPx(this.cellHeight),
      }
    },
    changeTab(item) {
      this.tabActive = item.value
      this.getData(item.value)
    },
    getData(dynamicType) {
      const params = {
        currentPage: 1,
        pageSize: 15,
      }
      if (dynamicType) {
        params.dynamicType = dynamicType
      }
      this.loading = true
      request(`${this.host}/open/statis/manage/dynamic`, {
        params
      }).then(res => {
        this.loading = false
        this.$emit('load')
        if (res.data.code === 200) {
          this.list = res.data.data.records.map(item => ({
            _id: new Date().getTime(),
            ...item,
            time: dayjs(item.dynamicTime).format('YYYY.MM.DD HH:MM'),
          }))
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab-box {
  display: flex;
  justify-content: space-between;
}

.top-right {
  color: #01ECFF;
  font-size: 14px;
}

.cell-img {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  margin-right: 5px;
}

.cell-product-title {
  display: flex;
  align-items: center;
}

.tab-box {
  display: flex;
  align-items: center;
}

.custom-tabs {
  display: flex;
  align-items: center;

  .tab-item {
    min-width: 44px;
    height: 28px;
    border-radius: 2px;
    padding: 4px 4px;
    margin-right: 8px;
    color: #76B5FD;
    text-align: center;
    cursor: pointer;
    line-height: 28px;
  }

  .active {
    background: #455DEA;
    color: #fff;
  }
}
</style>