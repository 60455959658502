<template>
  <div>
    <div class="tab-box">
      <div class="custom-tabs">
        <div class="tab-item" :class="{ active: item.value === tabActive }" @click="tabActive = item.value"
          v-for="item in tabs" :key="item.value">{{ item.title }}</div>
      </div>
    </div>
    <div class="avue-echart-table" style="margin-top: 30px">
      <el-table ref="table" :data="tableData" :height="650" :header-row-style="headerRowStyle"
        :header-cell-style="headerCellStyle" :cell-style="cellStyle" :row-style="rowStyle">
        <el-table-column show-overflow-tooltip prop="title" label="会员" width="90px">
          <template>
            <div class="cell-product-title">
              <img src="/api/file/Image/userAvatar/default_headIcon_07.jpg" class="cell-img">

              <span style="color: #76B5FD">李先生</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="info" label="动态信息">
          <template slot-scope="{row}">
            <div v-if="row.dynamicType === 'SEEK_HELP_ANSWER'">
              提交了“<span class="info-title">{{row.title}}</span>”问题的<span :style="{color: typeColor(row.dynamicType)}">{{ typeName(row.dynamicType) }}</span>
            </div>
            
            <div v-else style="color: #B1C7DE; font-weight: bold;">
              提交了“<span class="info-title">{{row.title}}</span>”
              <span :style="{color: typeColor(row.dynamicType)}">{{ typeName(row.dynamicType) }}</span>信息
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="time" label="时间" width="150px">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { setPx } from '../../echart/util'
import request from '../../axios'

export default {
  name: 'dynamicItem5',
  data() {
    return {
      headerHeight: '',
      // 动态类型，不传默认查询所有。SAD_SUPPLY:供应、SAD_DEMAND:求购、SEEK_HELP:求助、SEEK_HELP_ANSWER:求助回答、LABOR_RECRUIT:招工、LABOR_WORKER:找活
      tabs: [
        { title: '全部', value: 0, },
        { title: '供应', value: 'SAD_SUPPLY', color: '#01ECFF' },
        { title: '求购', value: 'SAD_DEMAND', color: '#E79232' },
        { title: '求助', value: 'SEEK_HELP', color: '#54BF59' },
        { title: '回答', value: 'SEEK_HELP_ANSWER', color: '#FAB2B2' },
        { title: '招工', value: 'LABOR_RECRUIT', color: '#6DB9FF' },
        { title: '找活', value: 'LABOR_WORKER', color: '#D6B05B' },
      ],
      tabActive: 0,
      list: [],
      headerRowStyle: {
        background: '#2B3F84'
      },
      headerCellStyle() {
        return {
          fontSize: 14,
          backgroundColor: '#2B3F84',
          color: '#FFFFFF',
          // textAlign: column.type == 'index' ? 'center' : this.option.headerTextAlign
        }
      },

      scroll: true,
      scrollTime: 2000,
      scrollSpeed: 5,
      count: 8,
      host: 'https://goat-api.sjxfbf.com'
    }
  },
  created() {
    this.$nextTick(() => {
      this.height = 650
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      setTimeout(() => {
        this.setTime();
      }, 3000)
    })
  },
  computed: {
    cellHeight() {
      return parseInt((650 - this.headerHeight) / this.count)
    },
    tableData() {
      if (this.tabActive === 0) return this.list
      return this.list.filter(item => item.dynamicType === this.tabActive)
    },
    typeColor() {
      return type => this.tabs.find(item => item.value === type).color
    },
    typeName() {
      return type => this.tabs.find(item => item.value === type).title
    }

  },
  mounted() {
    this.getData()
    setInterval(() => {
      this.getData()
    }, 1000 * 60 * 5)
  },
  methods: {
    setTime() {
      clearInterval(this.scrollCheck);
      this.height = 650
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      const table = this.$refs.table
      const divData = table.bodyWrapper
      const speed = this.scrollSpeed
      let top = 0
      if (this.scroll) {
        this.scrollCheck = setInterval(() => {
          top = top + speed
          divData.scrollTop += speed;
          if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
            divData.scrollTop = 0
          }
          if (top >= this.cellHeight && this.scrollTime) {
            divData.scrollTop = divData.scrollTop - (top - this.cellHeight)
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              this.setTime()
            }, this.scrollTime)
          }
        }, 20)
      } else {
        divData.scrollTop = 0
      }
    },
    rowStyle() {
      return {
        backgroundColor: 'transparent'
      }
    },
    cellStyle() {
      return {
        borderWidth: '0px 0px 1px 0px',
        borderStyle: 'dashed',
        borderColor: '#2D3C8A',
        backgroundColor: 'transparent',
        color: '#B1C7DE',
        padding: '24px 0',
        boxSizing: 'border-box',
        height: setPx(this.cellHeight),
      }
    },
    getData(dynamicType) {
      this.dynamicType = dynamicType
      const params = {
        currentPage: 1,
        pageSize: 15,
      }
      if (dynamicType) {
        params.dynamicType = dynamicType
      }
      this.loading = true
      request(`${this.host}/open/statis/user/dynamic`, {
        params
      }).then(res => {
        this.$emit('load')
        this.loading = false
        if (res.data.code === 200) {
          this.list = res.data.data.records.map(item => ({
            _id: new Date().getTime(),
            ...item,
            time: dayjs(item.dynamicTime).format('YYYY.MM.DD HH:MM'),
          }))
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab-box {
  display: flex;
  justify-content: space-between;
}

.top-right {
  color: #01ECFF;
  font-size: 14px;
}

.cell-img {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  margin-right: 5px;
}

.cell-product-title {
  display: flex;
  align-items: center;
}

.tab-box {
  display: flex;
  align-items: center;
}

.custom-tabs {
  display: flex;
  align-items: center;

  .tab-item {
    min-width: 44px;
    height: 28px;
    border-radius: 2px;
    padding: 4px 4px;
    margin-right: 8px;
    color: #76B5FD;
    text-align: center;
    cursor: pointer;
    line-height: 28px;
  }

  .active {
    background: #455DEA;
    color: #fff;
  }
  
}
.info-title {
    display: inline-block;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
  }
</style>