// import { Loading } from 'element-ui';
import { validatenull } from '@/echart/util'
import { checkUrl, getFunction } from '@/utils/utils'
import axios from 'axios';
import { Message } from 'element-ui';
import CryptoJS from 'crypto-js'

window.$glob = {
    url: '',
    params: {},
    query: {},
    headers: {}
};

function getGlobParams() {
    var query = window.location.search.substring(1);
    query = query.split("&");
    query.forEach(ele => {
        var pair = ele.split("=");
        window.$glob.params[pair[0]] = pair[1]
    })
}

axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500; // 默认的
};
  // 电商秘钥
  let  code = {
    appSecret:'c71bfdec6c3f6a756b07c1aa3bacd3582c9fa13c1c8ace8c9aeee111f3ee74e9',
  }
//   'grantType': 
//跨域请求，允许保存cookie
// let loadingInstance = '';
axios.defaults.withCredentials = false;
axios.interceptors.request.use(config => {
    // loadingInstance = Loading.service({
    //   text: '拼命加载中',
    //   background: 'rgba(0,0,0,0)',
    //   spinner: 'el-icon-loading'
    // });
    getGlobParams();
    if (!checkUrl(config.url)) {
        config.url = window.$glob.url + config.url;
    }
    if (!validatenull(window.$glob.header)) {
        let header = getFunction(window.$glob.header)();
        config.headers = Object.assign(config.headers, header);
    }
    //获取全局参数
    if (typeof (config.data) === 'object' && !validatenull(window.$glob.query)) {
        let query = getFunction(window.$glob.query)();
        let data = Object.assign(window.$glob.params, query)
        if (config.method == 'get') {
            config.params = Object.assign(config.params, data)
        } else if (config.method == 'post') {
            config.data = Object.assign(config.data, data)
        }
    }
    if(!config.headers['Authorization']) {
        const token = localStorage.getItem("token") || ''
        config.headers['Authorization'] = token
    }
    // 判断是否添加秘钥
    if(config.params && config.params.isKey && config.params.isKey === 'true') {
        let a = {...config.params, ...code}
        let b = {}
        let m = Object.keys(a).sort()
        m.filter(e => {
            if( e !== 'appId' && e !== 'sign' && a[e]){
              b[e] = a[e]
            }
        })
        config.params.sign =  CryptoJS.SHA256(JSON.stringify(b)).toString()
        config.headers['GrantType'] = 'sign'
    }
    return config
}, error => {
    return Promise.reject(error)
});
//HTTPrequest拦截
axios.interceptors.response.use(config => {
    // loadingInstance.close();
    const res = config.data
    if (res && res.code &&  res.code !== 200 ) {
        if (res.code  != '00000') {
            Message({
                message: res.msg || '请求出错，请重试',
                type: 'error',
                duration: 1500
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        }
    } 
    return config;
}, error => {
    // loadingInstance.close();
    return Promise.reject(new Error(error));
})

export default axios;
