<template>
  <div class="custom-swiper" :style="styleSizeName">
    <div class="swiper-container" ref="swiper">
      <div class="swiper-wrapper" :style="{opacity: itemLoadCount >= 5 ? 1 : 0}">
        <div class="swiper-slide swiper-item">
          <div class="box">
            <Item1 @load="itemLoad" />
          </div>
          <div class="title">商品动态</div>
        </div>
        <div class="swiper-slide swiper-item">
          <div class="box">
            <Item2 @load="itemLoad"/>
          </div>
          <div class="title">店铺动态</div>
        </div> 
        <div class="swiper-slide swiper-item">
          <div class="box">
            <Item3 @load="itemLoad"/>
          </div>
          <div class="title">活动动态</div>
        </div> 
        <div class="swiper-slide swiper-item">
          <div class="box">
            <Item4 @load="itemLoad"/>
          </div>
          <div class="title">掌门动态</div>
        </div> 
        <div class="swiper-slide swiper-item">
          <div class="box">
            <Item5 @load="itemLoad"/>
          </div>
          <div class="title">会员动态</div>
        </div> 
      </div>

    </div>
    <div id="msg"></div>
    <div class="dynamic-tabs">
      <div class="dynamic-tab-item" :class="{ active: active === item.value }" role="button" @click="setTab(item)" v-for="item in tabs" :key="item.value">{{ item.name }}</div>
    </div>
  </div>
</template>
<script>
import Item1 from './item1.vue'
import Item2 from './item2.vue'
import Item3 from './item3.vue'
import Item4 from './item4.vue'
import Item5 from './item5.vue'
export default {
  name: 'dynamic',
  data() {
    return {
      tabs: [
        { name: '商品动态', value: 0 },
        { name: '店铺动态', value: 1 },
        { name: '活动动态', value: 2 },
        { name: '掌门动态', value: 3  },
        { name: '会员动态', value: 4  },
      ],
      active: 0,
      itemLoadCount: 0
    }
  },
  components: {
    Item1,
    Item2,
    Item3,
    Item4,
    Item5
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initSwiper()
    // })
  },
  methods: {
    itemLoad() {
      this.itemLoadCount++
      console.log(this.itemLoadCount);
      if(this.itemLoadCount === 5) {
         this.$nextTick(() => {
          this.initSwiper()
        })
      }
    },
    initSwiper() {
      this.$nextTick(() => {
        // const width = parseFloat(this.styleSizeName.width) / 2.5;
        if (this.myswiper) {
          this.myswiper.destroy()
        }
        this.myswiper = new window.Swiper(this.$refs.swiper, {
          initialSlide: 0,
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          // loopAdditionalSlides: 5,
          centeredSlides: true,
          loop: true,
          loopedSlides: 5,
          autoplay: {
            delay: 10000,
          },
          on: {
            
            progress: function() {
              const width = 594 - 800
              for (let i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                slide.attr('progress', slideProgress)
                
                slide.css('transform-origin', slideProgress > 0 ? 'right' : 'left')
                let rotateY = 0
                rotateY = -slideProgress * 30 + 'deg'
                if(slideProgress > 1) {
                  rotateY = -30 + 'deg'
                }
                if(slideProgress < -1) {
                  rotateY = 30 + 'deg'
                }
                // const n = slideProgress - Math.floor(slideProgress)
                // let w = width - Math.cos(30*(Math.PI/180)) * width
                let translate = (slideProgress * width) - (Math.cos(30*(Math.PI/180)) * slideProgress * width)
                // if(slideProgress !== 0) {
                //   translate = translate -(594 - Math.cos(30*(Math.PI/180))*(594))
                // }
                let z = Math.sin(30*(Math.PI/180) * width * Math.abs(slideProgress) ) - 297 * (Math.abs(slideProgress ) - 1)
                if(slideProgress === 0 || Math.abs(slideProgress) === 1) {
                  z = 0
                }
                if(z > 0) {
                  z = 0
                }
                z = z + 'px'
                translate = translate + 'px'
                slide.transform(
                  "translateX(" + translate + ") translateZ(" + z + ") rotateY(" + rotateY + ")"
                );
                // let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                // slide.css("zIndex", zIndex);
              }

            },
            
            setTransition: function (transition) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                slide.transition(transition);
              }
            },
            slideChange:() => {
              if(this.myswiper) {
                this.active = this.myswiper.realIndex
              }
            },
          },
        });
      });
    },
    setTab(item) {
      this.active = item.value
      this.myswiper.slideToLoop(this.tabs.findIndex(({value}) => value === item.value))
    }

  }
}
</script>
<style lang="scss" scoped>
.box {
  position: relative;
  background: rgba(148, 161, 226, 0.1);
  border: 1px solid rgb(33, 78, 146);
  box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
  height: 700px;
  padding: 34px 20px;
  border-radius: 5px;
  
}
.swiper-item {
  width: 594px;
  padding-top: 20px;
  transform-style: preserve-3d;
  transition-property: transform;
  .title {
    position: absolute;
    width: 124px;
    height: 32px;
    line-height: 32px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url('./title.png') no-repeat center center;
    background-size: 100% 100%;
    color: #1BB6F6;
    text-shadow: 0px 0px 10px rgba(0, 249, 255, 0.3);
    font-weight: bold;
    font-family: Microsoft YaHei;
    font-size: 18px;
  }
}
.swiper-container {
  perspective: 1200px;
}
.swiper-wrapper {
  transform-style: preserve-3d;
}

.dynamic-tabs {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.dynamic-tab-item {
  position: relative;
  width: 215px;
  height: 72px;
  background: rgba(27, 43, 132, 0.45);
  border: 1px solid #214E92;
  backdrop-filter: blur(10px);
  box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
  font-size: 20px;
  color: #01ECFF;
  text-align: center;
  line-height: 72px;
  clip-path: polygon(0 5px, 5px 0, calc(100% - 5px) 0, 100% 5px, 100% calc(100% - 5px), calc(100% - 5px) 100% , 5px 100%, 0 calc(100% - 5px));
  margin: 15px;
}
.dynamic-tab-item::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('./tab-item-bg.png');
  background-size: 215px 72px;
}

.dynamic-tab-item.active {
  background: linear-gradient(0deg, #455DEA 0%, rgba(69,93,234,0.31) 47%, rgba(69,93,234,0.00) 100%);
  border: 1px solid ;
  border-image: linear-gradient(180deg, rgba(1,236,255,0.20) 0%, #01ECFF 100%);
  color: #fff;
}
</style>