<!-- 自定义配置 -->
<template>
  <div>

  </div>
</template>
<script>
export default {
  name: 'realTime',
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
