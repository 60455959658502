<template>
  <el-scrollbar class="build views">
    <container :id="id"
               :option="option"
               ref="container"></container>
  </el-scrollbar>
</template>
<script>
import Vue from 'vue'
import init from '@/mixins/'
import { website } from '@/config.js'
import apiConfig from "../api/config";
import {getUrlParam, computedUrl} from "../utils/utils";
import '../styles/common.scss'

Vue.prototype.$website = website;

// Vue.use(Avue, {
//     size: 'mini'
// });
// Vue.prototype.$website = {
//     ...window.$website,
//     url: apiConfig.devURI + window.$website.url
// };
Vue.prototype.$websiteUrl = apiConfig.devURI + website.url;
Vue.prototype.computedImgUrl = (imgUri) => {
    return computedUrl(apiConfig.devURI, imgUri, '/DataV')
};
// Vue.use(window.AVUE);
Vue.prototype.$website = website;
export default {
  props: {
    option: Object,
    id: [String, Number]
  },
  mixins: [init],
  created(){
    const token = getUrlParam('token') || ''
    if (token) {
      localStorage.setItem('token', token)
    }
  }
}
</script>
