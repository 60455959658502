<template>
  <div class="box">
      <div class="box-title">
        <div>
          <span 
            :class="['title-tag',  timeActive === e.value ? 'active' :'']" 
            v-for="e in timeType"  
            @click="typeCut(e)"
            :key="e.value">{{ e.name }}</span>
        </div>
          <el-date-picker
            class="date-picker"
            v-model="value"
            :type="timeActive"
            :format="timeActive === 'week' ? 'yyyy 第 WW 周' : '' "
            @change="changeTime"
            placeholder="选择日期">
          </el-date-picker>
      </div>
      <div class="box-list">
        <div class="list-title">
          <span style="width: 50px;">排名</span>
          <span style="flex: 1;">店铺</span>
          <span style="width: 100px;">营业额</span>
          <span style="width: 100px;">销量</span>
        </div>
        <div class="list-content">
          <vue-seamless-scroll 
            ref="seamlessScroll"
            style="height: 100%;"
            :data="list"
            :class-option="defaultOption">
            <div class="list-coat"  :style=" {'min-height': `${component.height - 105}px`}">
              <li class="list-item" v-for="(e, i) in list" :key="i">
                <span style="width: 50px; color: #fff;"  >
                  <span :class="{'tops': i < 3 }"  >
                    {{ i + 1 }}
                  </span>
                </span>
                <span style="
                    flex: 1; 
                    color: #76B5FD; 
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
              
                    ">
                  <el-avatar shape="square" size="medium" :src="`${IMGAPI}${e.shopLogo}`" style="margin-right: 15px;"></el-avatar>
                  <p style="
                    width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;">
                    {{ e.shopName }}
                  </p>
                 
                </span>
                <span style="width: 100px; font-size: 16px; color: #01ECFF; font-weight: bold;">{{ Number(e.amount) | getlimit}}</span>
                <span style="width: 100px; font-size: 16px; color: #01ECFF; font-weight: bold;">{{ e.sales ? e.sales : 0 }}单</span>
              </li>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import vueSeamlessScroll from 'vue-seamless-scroll'
import {unitConvert} from '@/utils/utils.js'
import {hotStore} from '@/api/carPlay.js'
import {StartEndTime} from '@/utils/utils.js'
import {IMGAPI} from'@/utils/define'

export default {
  name: 'hotStore',
  components:{
    vueSeamlessScroll
  },
  data () {
    return {
      value:new Date(dayjs().subtract(1, 'year')),
      timeValue: {
        startTime:'',
        endTime: ''
      },
      timeType: [
        {
          name: '按日',
          value: 'date'
        },
        {
          name: '按周',
          value: 'week'
        },
        {
          name: '按月',
          value: 'month'
        },
        {
          name: '按年',
          value: 'year'
        }
      ],
      timeActive: 'year',
      list: [],
      step: 1,
      IMGAPI
    }
  },
  props: {
    option: Object,
    component: Object
  },
  created() {
    this.changeTime()
  },
  watch: {
    'option.step' (val) {
      this.step = val
    }
  },
  filters:{
    getlimit(e){
      let a = unitConvert(e)
      return `${a.num}${a.unit}`
    }
  },
  computed: {
    defaultOption() {
      return {
        step: this.step, // 数值越大速度滚动越快
        limitMoveNum: this.list.length,
        hoverStop: this.option.hoverStop, // 是否开启鼠标悬停stop
        direction: this.option.direction, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    typeCut(e) {
      this.timeActive = e.value
      this.changeTime()
    },
    changeTime() {
      this.timeValue = StartEndTime(this.value, this.timeActive)
      this.getlist()
    },
    getlist() {
      let params ={
        startTime : this.timeValue.startTime,
        endTime: this.timeValue.endTime,
        appId: '450244186128',
        // 是否添加秘钥
        isKey: 'true',
        timestamp : new Date().getTime() + "",
        userId: ''
      }
      hotStore(params).then(res => {
        if(res.data.success){
          let a = JSON.parse(res.data.data)
          if(a.length > 0) {
            this.list = a
            // // 判断数量小于4时 停止滚动
            if(this.list.length <= 4) {
              this.step = 0
            }
          } else {
            this.step= 0
            this.list = []
          }
        } else {
          this.step = 0
          this.list = []
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: default;
    &-title{
      height: 40px;
      display: flex;
      flex-direction: row;
      color: #fff;
      justify-content: space-between;
      align-items: center;
      .title-tag{
        width: 44px;
        height: 28px;
        border-radius: 2px;
        padding: 4px 8px;
        margin-right: 8px;
        color: #76B5FD;
        text-align: center;
        cursor: pointer;
        line-height: 28px;
        display: inline-block;
      }
      .active{
        background-color: #455DEA;
        color: #fff;
      }
      .date-picker{
        cursor: pointer;
      }
      
      ::v-deep .date-picker .el-input__inner {
        cursor: pointer;
        padding-left: 15px;
        background: rgba(0, 10, 53, 0.01) !important;
        border: 1px solid #214E92 !important;
        box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
        color: #B1C7DE !important;
        border-radius: 2px;
      }

      ::v-deep .date-picker .el-input__prefix {
        right: 0;
        left: auto;
        width: 40px;
        background: #455DEA;
        box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
        color: #fff;
      }

      ::v-deep .date-picker.el-input{
        width: 150px;
      }

      ::v-deep .date-picker .el-input__suffix {
        right: 45px;
      }
    }
    &-list{
      flex: 1;
      margin-top: 25px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .list-title{
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #0C1D55;
        color: #fff;
        span{
          text-align: center;
        }
      }
      .list-content{
        list-style: none;
        box-sizing: border-box;
        height: calc(100% - 40px);
        overflow: hidden;
        .list-item{
          height: 70px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px dashed #2D3C8A;
          span{
            text-align: center;
          }
          .tops {
            width: 20px;
            height: 23px;
            background-image: url(/img/three.png);
            background-size: 20px 23px;
            display: inline-block;
            line-height: 20px;
          }
        }

      }
    }
  }
</style>
