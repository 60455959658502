<template>
  <div class="box">
      <div class="box-title">
        <span class="title-tag">按日</span>
          <el-date-picker
            v-model="timeValue"
            class="date-picker"
            type="date"
            @change="timeChange"
            placeholder="选择日期">
          </el-date-picker>
      </div>
      <div class="box-list">
          <div v-for="(e, i) in dataNum" :key="i" class="box-line" >
            <div v-for="(m, i) in e" :key="i" class="box-item">
              <span class="span"></span>
              {{ m.name }}
              <span class="value">{{ m.value ? m.value: 0 }}</span>
            </div>
           
          </div>
      </div>
  </div>
</template>
<script>
import {realTime} from '@/api/carPlay.js'
import dayjs from "dayjs";
export default {
  name: 'realTime',
  data () {
    return {
      timeValue: new Date(),
      dataNum:[
          [
          {
            name: '今日的订单数',
            code: 'currentOrderNum',
            value: ''
          },
          {
            name: '今日交易额',
            code: 'currentPayAmount',
            value: ''
          },
        ],
        [
          {
            name: '今日新增会员数量',
            code: 'currentUserNum',
            value: ''
          },
          {
            name: '今日新增商品数',
            code: 'currentProdNum',
            value: ''
          },
        ],
        [
          {
            name: '今日新增店铺',
            code: 'currentShopNum',
            value: ''
          },
          {
            name: '今日新增评论',
            code: 'currentProdCommNum',
            value: ''
          },
        ],
        [
          {
            name: '今日访客数',
            code: 'todayVisitors',
            value: ''
          },
          {
            name: '昨日访客数',
            code: 'yesterdayVisitors',
            value: ''
          },
        ],
        [
          {
            name: '前七日访客数',
            code: 'firstSevenDayVisitors',
            value: ''
          },
          {
            name: '前三十日访客数',
            code: 'firstThirtyDayVisitors',
            value: ''
          }
        ]
        ]
    }
  },
  props: {
    option: Object,
    component: Object
  },
  created() {
    this.getlist()
  },
  methods: {
    timeChange() {
      this.getlist()
    },
    getlist() {
      let params ={
        startTime : dayjs(this.timeValue).format('YYYY-MM-DD'),
        appId: '450244186128',
        // 是否添加秘钥
        isKey: 'true',
        timestamp : new Date().getTime() + "",
        userId: ''
      }
      realTime(params).then(res => {
        if(res.data.success){
          let a = JSON.parse(res.data.data)
          this.dataNum .forEach(e => {
            e.forEach(i => {
              i.value = a[i.code]
            })
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: default;
    &-title{
      height: 40px;
      display: flex;
      flex-direction: row;
      color: #fff;
      justify-content: space-between;
      align-items: center;
      .title-tag{
        width: 44px;
        height: 28px;
        border-radius: 2px;
        padding: 4px 8px;
        margin-right: 8px;
        background: #455DEA;
        color: #fff;
        text-align: center;
        cursor: pointer;
        line-height: 28px;
        display: inline-block;
      }
      ::v-deep .date-picker .el-input__inner {
        padding-left: 15px;
        background: rgba(0, 10, 53, 0.01) !important;
        border: 1px solid #214E92 !important;
        box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
        color: #B1C7DE !important;
        border-radius: 2px;
      }

      ::v-deep .date-picker .el-input__prefix {
        right: 0;
        left: auto;
        width: 40px;
        background: #455DEA;
        box-shadow: inset 0px 0px 30px 0px rgba(97, 168, 255, 0.3);
        color: #fff;
      }
      ::v-deep .date-picker.el-input{
        width: 150px;
      }

      ::v-deep .date-picker .el-input__suffix {
        right: 45px;
      }
    }
    &-list{
        margin-top: 25px;
        flex: 1;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        .box-line{
          flex: 1;
          font-size: 16px;
          color: #76B5FD;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .box-item {
          padding-left: 25px;
          background-color:unset !important;
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .span{
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #76B5FD;
          border-radius: 50%;
          margin-right: 5px;
        }
        .value{
          margin-left: 8px;
          font-size: 28px;
          font-weight: bold;
          color: #01ECFF;
        }
    }
    &-list :nth-child(2n) {
          background-color: #0C1D55;
        }
  }

</style>
