import {url} from '@/config';
import request from '../axios'
// const apiConfig = require('./config');

export const getList = (params) => request({
    url: 'https://data.avuejs.com/api/blade-visual/map/list',
    method: 'get',
    params: params
});


export const getObj = (id) => request({
    url: url + '/map/detail',
    method: 'get',
    params: {
        id
    }
});

export const addObj = (data) => request({
    url: url + '/map/save',
    method: 'post',
    data: data
});
export const updateObj = (data) => request({
    url: url + '/map/update',
    method: 'post',
    data: data
});


export const delObj = (id) => request({
    url: url + '/map/remove',
    method: 'post',
    params: {
        ids: id
    }
});



export const china= () => request({
    url:'https://www.sjxfbf.com/map/china.json',
    method: 'get',
    withCredentials: false
})

export const province= (params) => request({
    url: `https://www.sjxfbf.com/map/province/${params}.json`,
    method: 'get',
    withCredentials: false
});
export const city= (params) => request({
    url: `https://www.sjxfbf.com/map/city/${params}.json`,
    method: 'get',
    withCredentials: false
});
export const county= (params) => request({
    url: `https://www.sjxfbf.com/map/county/${params}.json`,
    method: 'get',
    withCredentials: false
});

// 获取地区级联
export const AreaCode = params => {
    return request({
      url: `https://goat-api.sjxfbf.com/api/system/Area/select`,  
      method: 'get',
      params 
    })
  }

// 获取地区展示数据
export const Areadata = params => {
    return request({
      url: `https://goat-api.sjxfbf.com/open/statis/isp/channelCount`,  
      method: 'get',
      params 
    })
  }
